import "./Style.scss";
import Header from "../components/Header";
import Footer from "../components/Footer";
import SocialMedia from "../components/SocialMedia";
import Address from "../components/Address";
import JoinUs from "../components/JoinUs";
import Button from "../components/Button";
import Banner from "../components/Banner";


function Contact(){
    return(
        <div className="page">
            <Header active="contact" />

            <Banner></Banner>
            
            <div className="seperator"><div className="container"></div></div>

            <div className="section">
                <div className="container">
                    <h1 className="colorWhite text-start" style={{marginBottom:"30px"}}>Contact Us</h1>
                    
                    <div className="row text-start">
                        <div className="col-12 col-md-8">
                            <form>
                                <div className="row">
                                    <div className="col-6 mb-3">
                                        <label className="form-label">First name</label>
                                        <input type="text" className="form-control" id="exampleInputFirstName" aria-describedby="firstNameHelp" />
                                    </div>
                                    <div className="col-6 mb-3">
                                        <label className="form-label">Last name</label>
                                        <input type="text" className="form-control" id="exampleInputLastName" />
                                    </div>
                                </div>
                                
                                <div className="mb-3">
                                    <label className="form-label">Email address</label>
                                    <input type="email" className="form-control" id="exampleInputEmail" aria-describedby="emailHelp" />
                                </div>

                                <div className="mb-3">
                                    <label className="form-label">Phone</label>
                                    <input type="email" className="form-control" id="exampleInputPhone" aria-describedby="phoneHelp" />
                                </div>
                                
                                <div className="mb-3">
                                    <label className="form-label">Message</label>
                                    <textarea className="form-control" id="exampleInputMessage" style={{minHeight: "130px"}}></textarea>
                                </div>
                                
                                <button type="submit" className={"btn customButton filled blueTheme"} style={{marginTop:"30px", width: "150px", height: "50px"}}>
                                    Submit
                                </button>                                
                            </form>
                        </div>

                        <div className="col-12 col-md-1"></div>

                        <div className="col-12 col-md-3 text-center text-md-start mt-5 mt-md-0">
                            <div className="mb-5">
                                <div className="pageHeading">
                                    <h2>&nbsp;</h2>
                                </div>
                                <Address heading="Our address" />
                            </div>

                            <div className="mb-5">
                                <h4 className="text-uppercase" style={{color:"#AAA"}}>Email</h4>
                                <a href="mailto:contact@astungaming.com">contact@astungaming.com</a>
                            </div>

                            <div className="mb-5">
                                <h4 className="text-uppercase" style={{color:"#AAA"}}>Phone</h4>
                                <a href="tel:+918588865196">+91-85888 65196</a>
                            </div>

                            <SocialMedia heading="Follow Us" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="seperator"><div className="container"></div></div>

            <JoinUs></JoinUs>

            <Footer />
        </div>
    );
}

export default Contact;