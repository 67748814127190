import "./Style.scss";
import Button from "../components/Button";
import { Link } from "react-router-dom";

function GameCards(props){
    return(
        <div>
            <div className="row" style={{marginTop: "40px"}}>
                <div className="col-md-6 col-xl-3">
                    <a target="new" href="http://casino.astungaming.com/?game=indiaRoulette">
                        <div className="gameCard">
                            <img src="./assets/images/gameIcons/IndianRoulette.png" />
                            {props.showDescription == "true"?(
                                <div className="aboutCard">
                                    <div className="gameName">
                                        Indian Roulette
                                    </div>
                                    Indian version of Roulette 50-50000 INR
                                    <div className="playDemoLink">Play Demo</div>
                                </div>
                            ):""}
                        </div>
                    </a>
                </div>
                <div className="col-md-6 col-xl-3">
                    <a target="new" href="http://casino.astungaming.com/?game=andarBahar">
                        <div className="gameCard">
                            <img src="./assets/images/gameIcons/AndarBahar.png" />
                            {props.showDescription == "true"?(
                                <div className="aboutCard">
                                    <div className="gameName">
                                        Andar Bahar
                                    </div>
                                    Andar Bahar 1-50 INR
                                    <div className="playDemoLink">Play Demo</div>
                                </div>
                            ):""}
                        </div>
                    </a>
                </div>
                <div className="col-md-6 col-xl-3">
                    <a target="new" href="http://casino.astungaming.com/?game=sicbo">
                        <div className="gameCard">
                            <img src="./assets/images/gameIcons/Sicbo.png" />
                            {props.showDescription == "true"?(
                                <div className="aboutCard">
                                    <div className="gameName">
                                        Sicbo
                                    </div>
                                    Sicbo delux - Single player 50-500000 INR
                                    <div className="playDemoLink">Play Demo</div>
                                </div>
                            ):""}
                        </div>
                    </a>
                </div>
                <div className="col-md-6 col-xl-3">
                    <a target="new" href="http://casino.astungaming.com/?game=blackJack">
                        <div className="gameCard">
                            <img src="./assets/images/gameIcons/BlackJack.png" />
                            {props.showDescription == "true"?(
                                <div className="aboutCard">
                                    <div className="gameName">
                                        Black Jack
                                    </div>
                                    Black Jack ultimate - Single player 10-100000 INR
                                    <div className="playDemoLink">Play Demo</div>
                                </div>
                            ):""}
                        </div>
                    </a>
                </div>
            </div>

            {props.showAll == "true"?(
                <div className="row">
                    <div className="col-md-6 col-xl-3">
                        <a target="new" href="http://casino.astungaming.com/?game=baccarat">
                            <div className="gameCard">
                                <img src="./assets/images/gameIcons/Baccarat.png" />
                                {props.showDescription == "true"?(
                                    <div className="aboutCard">
                                        <div className="gameName">
                                            Baccarat
                                        </div>
                                        Baccarat Spreme - Single player 100-80000 INR
                                        <div className="playDemoLink">Play Demo</div>
                                    </div>
                                ):""}
                            </div>
                        </a>
                    </div>
                    <div className="col-md-6 col-xl-3">
                        <a target="new" href="http://casino.astungaming.com/?game=fascinatePharaoh">
                            <div className="gameCard disabled">
                                <img src="./assets/images/gameIcons/SlotPharaoh.png" />
                                {props.showDescription == "true"?(
                                    <div className="aboutCard">
                                        <div className="gameName">
                                            Fascinate Pharaoh
                                        </div>
                                        Fascinate Pharaoh - Slot Game 10-100 INR
                                        <div className="playDemoLink">Coming soon</div>
                                    </div>
                                ):""}
                            </div>
                        </a>
                    </div>
                    <div className="col-md-6 col-xl-3">
                        <a target="new" href="http://casino.astungaming.com/?game=andarBahar2">
                            <div className="gameCard disabled">
                                <img src="./assets/images/gameIcons/AndarBahar2.png" />
                                {props.showDescription == "true"?(
                                    <div className="aboutCard">
                                        <div className="gameName">
                                            Andar Bahar 2
                                        </div>
                                        Andar Bahar - Multiplayer 500-500000 INR
                                        <div className="playDemoLink">Coming soon</div>
                                    </div>
                                ):""}
                            </div>
                        </a>
                    </div>
                    <div className="col-md-6 col-xl-3">
                        <a target="new" href="http://casino.astungaming.com/?game=indiaRoulette2">
                            <div className="gameCard disabled">
                                <img src="./assets/images/gameIcons/IndianRoulette2.png" />
                                {props.showDescription == "true"?(
                                    <div className="aboutCard">
                                        <div className="gameName">
                                            Indian Roulette 2
                                        </div>
                                        Indian Roulette - Multiplayer 10-100 INR
                                        <div className="playDemoLink">Coming soon</div>
                                    </div>
                                ):""}
                            </div>
                        </a>
                    </div>
                    <div className="col-md-6 col-xl-3"> </div>
                </div>
            ):""}
            
        </div>
    );
}
export default GameCards;