import "./Style.scss";

function Banner(props){
    return (
        <div className="banner">
            <img src="./assets/images/banner4.jpg" />
        </div>
    );
    
}
export default Banner;