import "./Style.scss";
import Header from "../components/Header";
import Button from "../components/Button";
import Hero from "../components/Hero";
import JoinUs from "../components/JoinUs";
import WhyChooseUs from "../components/WhyChooseUs";
import GameCards from "./GameCards";
import Footer from "../components/Footer";


function Home(){
    return(
        <div className="page">
            <Header active="home" />

            <Hero bannerName="homePageHero"></Hero>

            <div className="seperator"><div className="container"></div></div>
            
            <div className="section">
                <div className="container">
                    <div className="descriptionText" style={{fontWeight: "bold", fontSize:"20px", color:"#777"}}>
                        We are a provider of innovative gaming solutions. Our expertise lies in creating engaging,
                        high-quality casino games that drive player satisfaction and maximize revenue for our partners.
                    </div>

                    <GameCards showAll="false" showDescription="false"></GameCards>
            
                    <Button name="View All" link={process.env.PUBLIC_URL + "/games"} className="filled blackTheme" style={{marginTop: "40px", width: "120px", height: "50px"}}></Button>
                </div>
            </div>

            <div className="seperator"><div className="container"></div></div>

            <div className="section serviceSection">
                <div className="container">
                    <h1>What <span className="colorWhite">we do</span></h1>
                    <div className="descriptionText" style={{marginBottom:"30px"}}>
                        We're not just a gaming company; we're a squad of gaming enthusiasts on a mission to create epic fun.
                        We're the mad scientists of entertainment, mixing code, creativity, and caffeine to concoct gaming gold.
                    </div>
                    
                    <div className="row">
                        <div className="col-sm-12 col-md-1"></div>

                        <div className="col-sm-12 col-md-2">
                            <div className="cardContainer">
                                {/* <img src="./assets/images/icons/icon_GameDvelopment.svg" /> */}
                                <svg xmlns="http://www.w3.org/2000/svg" className="icons" width="1em" height="1em" viewBox="0 0 24 24"><path fill="#ffc107" fill-rule="evenodd" d="M15 2.25a.75.75 0 0 1 .75.75v1A1.75 1.75 0 0 1 14 5.75h-1a.25.25 0 0 0-.25.25v.25h.533c1.313 0 2.354 0 3.194.083c.863.085 1.583.262 2.234.66q.465.285.854.66c.55.53.895 1.178 1.179 1.981c.275.78.512 1.768.81 3.01l1.095 4.557c.483 2.011-.805 3.994-2.827 4.453c-1.702.386-3.468-.419-4.248-1.97l-.129-.255a2.66 2.66 0 0 0-2.381-1.44h-2.128c-1.021 0-1.942.567-2.381 1.44l-.128.255c-.781 1.551-2.547 2.356-4.249 1.97c-2.022-.46-3.31-2.442-2.827-4.453l1.095-4.556c.298-1.242.536-2.231.81-3.01c.284-.804.628-1.452 1.179-1.982q.391-.375.854-.66c.651-.398 1.372-.575 2.234-.66c.84-.083 1.88-.083 3.194-.083h.533V6c0-.966.784-1.75 1.75-1.75h1a.25.25 0 0 0 .25-.25V3a.75.75 0 0 1 .75-.75m-4.243 5.5c-1.362 0-2.329 0-3.087.076c-.743.073-1.214.212-1.598.447a3.5 3.5 0 0 0-.597.461c-.32.308-.563.717-.804 1.4c-.246.697-.466 1.61-.777 2.902L2.81 17.552c-.28 1.164.46 2.357 1.7 2.64c1.05.238 2.115-.265 2.577-1.183l.128-.254c.701-1.394 2.149-2.265 3.721-2.265h2.128c1.572 0 3.02.871 3.721 2.265l.128.254c.462.918 1.527 1.42 2.577 1.182c1.241-.282 1.98-1.475 1.7-2.64l-1.084-4.515c-.31-1.292-.53-2.205-.777-2.903c-.24-.682-.484-1.091-.804-1.399a3.5 3.5 0 0 0-.597-.46c-.384-.236-.855-.375-1.598-.448c-.758-.075-1.725-.076-3.087-.076zM8.5 10.25a.75.75 0 0 1 .75.75v.75H10a.75.75 0 0 1 0 1.5h-.75V14a.75.75 0 0 1-1.5 0v-.75H7a.75.75 0 0 1 0-1.5h.75V11a.75.75 0 0 1 .75-.75" clip-rule="evenodd"/><path fill="#ffc107" d="M16 11a1 1 0 1 1-2 0a1 1 0 0 1 2 0m2 3a1 1 0 1 1-2 0a1 1 0 0 1 2 0"/></svg>
                                <div className="cardTitle">
                                    Game development
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-2">
                            <div className="cardContainer">
                                {/* <img src="./assets/images/icons/Icon_GamingFramework.svg" /> */}
                                <svg xmlns="http://www.w3.org/2000/svg" className="icons" width="1em" height="1em" viewBox="0 0 32 32"><path fill="#ffc107" d="M8 4v4H4V4zM2 2v8h8V2zm16 5v4h-4V7zm-6-2v8h8V5zM8 16v4H4v-4zm-6-2v8h8v-8z"/><path fill="#ffc107" d="M22 10v6h-6v6h-6v8h20V10Zm-4 8h4v4h-4Zm-2 10h-4v-4h4Zm6 0h-4v-4h4Zm6 0h-4v-4h4Zm0-6h-4v-4h4Zm-4-6v-4h4v4Z"/></svg>
                                <div className="cardTitle">
                                    Gaming Framework
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-2">
                            <div className="cardContainer">
                                {/* <img src="./assets/images/icons/Icon_Hexagon.svg" /> */}
                                <svg xmlns="http://www.w3.org/2000/svg" className="icons" width="1em" height="1em" viewBox="0 0 24 24"><path fill="#ffc107" d="M12 14q1.8 0 2.9.663t1.1 1.762q0 .25-.162.413t-.413.162h-6.85q-.25 0-.412-.162T8 16.425q0-1.1 1.1-1.763T12 14m0-1q-.825 0-1.412-.587T10 11t.588-1.412T12 9t1.413.588T14 11t-.587 1.413T12 13M1 17V7q0-1.425 2.838-2.2T12 4.025t8.163.775T23 7v10q0 .975-1.125 1.725T18.65 19.9q-.65.125-1.15-.312T17 18.5V9.65q0-.65.425-1.162T18.5 7.85q.775-.175 1.338-.362t.837-.388Q19.6 6.675 17 6.338T12 6t-5 .338t-3.675.762q.275.2.838.4t1.337.375q.65.15 1.075.638T7 9.65v8.85q0 .65-.5 1.088t-1.15.312q-2.1-.425-3.225-1.175T1 17"/></svg>
                                <div className="cardTitle">
                                    Game simulators
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-2">
                            <div className="cardContainer">
                                {/* <img src="./assets/images/icons/Icon_Hexagon.svg" /> */}
                                <svg xmlns="http://www.w3.org/2000/svg" className="icons" width="1em" height="1em" viewBox="0 0 512 512"><path fill="#ffc107" d="M495.24 267.592L445.066 41.083A32.04 32.04 0 0 0 406.9 16.76L180.393 66.934a32 32 0 0 0-24.322 38.166l21.021 94.9H48a32.036 32.036 0 0 0-32 32v232a32.036 32.036 0 0 0 32 32h232a32.036 32.036 0 0 0 32-32V340.957l158.917-35.2a32.04 32.04 0 0 0 24.323-38.165M280 464H48V232h136.181l22.063 99.606a32.03 32.03 0 0 0 31.18 25.092a32.3 32.3 0 0 0 6.984-.769l35.6-7.886L280.02 464Zm184-189.487l-226.513 50.173l-50.173-226.51L413.824 48l50.193 226.505Z"/><path fill="#ffc107" d="M80 264h40v40H80zm0 128h40v40H80zm128 0h40v40h-40zm-64-64h40v40h-40zm81.456-205.433l39.054-8.644l8.644 39.055l-39.054 8.644zm152.672 97.223l39.054-8.65l8.65 39.054l-39.054 8.65zm-76.324-48.649l39.053-8.65l8.65 39.053l-39.052 8.65z"/></svg>
                                <div className="cardTitle">
                                    Social casino solutions
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-2">
                            <div className="cardContainer">
                                {/* <img src="./assets/images/icons/Icon_Hexagon.svg" /> */}
                                <svg xmlns="http://www.w3.org/2000/svg" className="icons" width="1em" height="1em" viewBox="0 0 24 24"><path fill="none" stroke="#ffc107" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M7 15.625h3.5m3.25-5.5h3.5m-3.5-2.5h3.5m-10.5 1.25h2m0 0h2m-2 0v-2m0 2v2m5.35 6.25l1.414-1.414m0 0l1.415-1.414m-1.415 1.414L14.1 14.296m1.414 1.415l1.415 1.414M3 9.4c0-2.24 0-3.36.436-4.216a4 4 0 0 1 1.748-1.748C6.04 3 7.16 3 9.4 3h5.2c2.24 0 3.36 0 4.216.436a4 4 0 0 1 1.748 1.748C21 6.04 21 7.16 21 9.4v5.2c0 2.24 0 3.36-.436 4.216a4 4 0 0 1-1.748 1.748C17.96 21 16.84 21 14.6 21H9.4c-2.24 0-3.36 0-4.216-.436a4 4 0 0 1-1.748-1.748C3 17.96 3 16.84 3 14.6z"/></svg>
                                <div className="cardTitle">
                                    Math magic
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-1"></div>
                    </div>

                    <div>
                        <Button link={process.env.PUBLIC_URL + "/services"} name="View all services" className="filled blackTheme" style={{marginTop:"30px", width: "190px", height: "50px"}}></Button>
                    </div>
                </div>
            </div>

            <div className="seperator"><div className="container"></div></div>
            
            <WhyChooseUs></WhyChooseUs>

            <div className="seperator"><div className="container"></div></div>

            <JoinUs></JoinUs>

            <Footer />
        </div>
    );
}
export default Home;