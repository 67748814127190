import "./Style.scss";
import Header from "../components/Header";
import Footer from "../components/Footer";
import JoinUs from "../components/JoinUs";
import GameCards from "./GameCards";
import Hero from "../components/Hero";


function Portfolio(){
    return(
        <div className="page">

            <Header active="portfolio" />
            
            <Hero bannerName="portfolioPageHero"></Hero>

            <div className="seperator"><div className="container"></div></div>

            <div className="section">
                <div className="container">
                    <h1>
                        Explore our <span style={{color:"white"}}>Gaming portfolio</span>
                    </h1>

                    <GameCards showAll="true" showDescription="true"></GameCards>
                </div>
            </div>

            <div className="seperator"><div className="container"></div></div>

            <JoinUs></JoinUs>

            <Footer />
        </div>
    );
}

export default Portfolio;