import "./Style.scss";
import Header from "../components/Header";
import Footer from "../components/Footer";
import JoinUs from "../components/JoinUs";
import WhyChooseUs from "../components/WhyChooseUs";
import Button from "../components/Button";


function Services(){
    return(
        <div className="page">
            <Header active="services" />
            
            <div className="section serviceSection">
                <div className="container">
                    <h1>What <span className="colorWhite">we do</span></h1>
                    <div className="descriptionText" style={{marginBottom:"30px"}}>
                        With a specialized product division, our company's experts can handle any type of task; 
                        our organization knows how to produce valuable apps that capture a large audience. 
                        Clients have every opportunity to immerse themselves into their products and contribute 
                        to well-designed prototypes that promote themselves.
                    </div>
                    
                    <div className="row">
                        <div className="col-sm-12 col-md-1"></div>

                        <div className="col-sm-12 col-md-2">
                            <div className="cardContainer">
                                {/* <img src="./assets/images/icons/icon_GameDvelopment.svg" /> */}
                                {/* <i class="bi bi-controller icons"></i> */}
                                <svg xmlns="http://www.w3.org/2000/svg" className="icons" width="1em" height="1em" viewBox="0 0 24 24"><path fill="#ffc107" fill-rule="evenodd" d="M15 2.25a.75.75 0 0 1 .75.75v1A1.75 1.75 0 0 1 14 5.75h-1a.25.25 0 0 0-.25.25v.25h.533c1.313 0 2.354 0 3.194.083c.863.085 1.583.262 2.234.66q.465.285.854.66c.55.53.895 1.178 1.179 1.981c.275.78.512 1.768.81 3.01l1.095 4.557c.483 2.011-.805 3.994-2.827 4.453c-1.702.386-3.468-.419-4.248-1.97l-.129-.255a2.66 2.66 0 0 0-2.381-1.44h-2.128c-1.021 0-1.942.567-2.381 1.44l-.128.255c-.781 1.551-2.547 2.356-4.249 1.97c-2.022-.46-3.31-2.442-2.827-4.453l1.095-4.556c.298-1.242.536-2.231.81-3.01c.284-.804.628-1.452 1.179-1.982q.391-.375.854-.66c.651-.398 1.372-.575 2.234-.66c.84-.083 1.88-.083 3.194-.083h.533V6c0-.966.784-1.75 1.75-1.75h1a.25.25 0 0 0 .25-.25V3a.75.75 0 0 1 .75-.75m-4.243 5.5c-1.362 0-2.329 0-3.087.076c-.743.073-1.214.212-1.598.447a3.5 3.5 0 0 0-.597.461c-.32.308-.563.717-.804 1.4c-.246.697-.466 1.61-.777 2.902L2.81 17.552c-.28 1.164.46 2.357 1.7 2.64c1.05.238 2.115-.265 2.577-1.183l.128-.254c.701-1.394 2.149-2.265 3.721-2.265h2.128c1.572 0 3.02.871 3.721 2.265l.128.254c.462.918 1.527 1.42 2.577 1.182c1.241-.282 1.98-1.475 1.7-2.64l-1.084-4.515c-.31-1.292-.53-2.205-.777-2.903c-.24-.682-.484-1.091-.804-1.399a3.5 3.5 0 0 0-.597-.46c-.384-.236-.855-.375-1.598-.448c-.758-.075-1.725-.076-3.087-.076zM8.5 10.25a.75.75 0 0 1 .75.75v.75H10a.75.75 0 0 1 0 1.5h-.75V14a.75.75 0 0 1-1.5 0v-.75H7a.75.75 0 0 1 0-1.5h.75V11a.75.75 0 0 1 .75-.75" clip-rule="evenodd"/><path fill="#ffc107" d="M16 11a1 1 0 1 1-2 0a1 1 0 0 1 2 0m2 3a1 1 0 1 1-2 0a1 1 0 0 1 2 0"/></svg>
                                <div className="cardTitle">
                                    Game development
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-2">
                            <div className="cardContainer">
                                {/* <img src="./assets/images/icons/Icon_GamingFramework.svg" /> */}
                                <svg xmlns="http://www.w3.org/2000/svg" className="icons" width="1em" height="1em" viewBox="0 0 32 32"><path fill="#ffc107" d="M8 4v4H4V4zM2 2v8h8V2zm16 5v4h-4V7zm-6-2v8h8V5zM8 16v4H4v-4zm-6-2v8h8v-8z"/><path fill="#ffc107" d="M22 10v6h-6v6h-6v8h20V10Zm-4 8h4v4h-4Zm-2 10h-4v-4h4Zm6 0h-4v-4h4Zm6 0h-4v-4h4Zm0-6h-4v-4h4Zm-4-6v-4h4v4Z"/></svg>
                                <div className="cardTitle">
                                    Gaming Framework
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-2">
                            <div className="cardContainer">
                                {/* <img src="./assets/images/icons/Icon_Hexagon.svg" /> */}
                                <svg xmlns="http://www.w3.org/2000/svg" className="icons" width="1em" height="1em" viewBox="0 0 24 24"><path fill="#ffc107" d="M12 14q1.8 0 2.9.663t1.1 1.762q0 .25-.162.413t-.413.162h-6.85q-.25 0-.412-.162T8 16.425q0-1.1 1.1-1.763T12 14m0-1q-.825 0-1.412-.587T10 11t.588-1.412T12 9t1.413.588T14 11t-.587 1.413T12 13M1 17V7q0-1.425 2.838-2.2T12 4.025t8.163.775T23 7v10q0 .975-1.125 1.725T18.65 19.9q-.65.125-1.15-.312T17 18.5V9.65q0-.65.425-1.162T18.5 7.85q.775-.175 1.338-.362t.837-.388Q19.6 6.675 17 6.338T12 6t-5 .338t-3.675.762q.275.2.838.4t1.337.375q.65.15 1.075.638T7 9.65v8.85q0 .65-.5 1.088t-1.15.312q-2.1-.425-3.225-1.175T1 17"/></svg>
                                <div className="cardTitle">
                                    Game simulators
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-2">
                            <div className="cardContainer">
                                {/* <img src="./assets/images/icons/Icon_Hexagon.svg" /> */}
                                <svg xmlns="http://www.w3.org/2000/svg" className="icons" width="1em" height="1em" viewBox="0 0 512 512"><path fill="#ffc107" d="M495.24 267.592L445.066 41.083A32.04 32.04 0 0 0 406.9 16.76L180.393 66.934a32 32 0 0 0-24.322 38.166l21.021 94.9H48a32.036 32.036 0 0 0-32 32v232a32.036 32.036 0 0 0 32 32h232a32.036 32.036 0 0 0 32-32V340.957l158.917-35.2a32.04 32.04 0 0 0 24.323-38.165M280 464H48V232h136.181l22.063 99.606a32.03 32.03 0 0 0 31.18 25.092a32.3 32.3 0 0 0 6.984-.769l35.6-7.886L280.02 464Zm184-189.487l-226.513 50.173l-50.173-226.51L413.824 48l50.193 226.505Z"/><path fill="#ffc107" d="M80 264h40v40H80zm0 128h40v40H80zm128 0h40v40h-40zm-64-64h40v40h-40zm81.456-205.433l39.054-8.644l8.644 39.055l-39.054 8.644zm152.672 97.223l39.054-8.65l8.65 39.054l-39.054 8.65zm-76.324-48.649l39.053-8.65l8.65 39.053l-39.052 8.65z"/></svg>
                                <div className="cardTitle">
                                    Social casino solutions
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-2">
                            <div className="cardContainer">
                                {/* <i class="bi bi-infinity icons"></i> */}
                                <svg xmlns="http://www.w3.org/2000/svg" className="icons" width="1em" height="1em" viewBox="0 0 24 24"><path fill="none" stroke="#ffc107" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M7 15.625h3.5m3.25-5.5h3.5m-3.5-2.5h3.5m-10.5 1.25h2m0 0h2m-2 0v-2m0 2v2m5.35 6.25l1.414-1.414m0 0l1.415-1.414m-1.415 1.414L14.1 14.296m1.414 1.415l1.415 1.414M3 9.4c0-2.24 0-3.36.436-4.216a4 4 0 0 1 1.748-1.748C6.04 3 7.16 3 9.4 3h5.2c2.24 0 3.36 0 4.216.436a4 4 0 0 1 1.748 1.748C21 6.04 21 7.16 21 9.4v5.2c0 2.24 0 3.36-.436 4.216a4 4 0 0 1-1.748 1.748C17.96 21 16.84 21 14.6 21H9.4c-2.24 0-3.36 0-4.216-.436a4 4 0 0 1-1.748-1.748C3 17.96 3 16.84 3 14.6z"/></svg>
                                <div className="cardTitle">
                                    Math magic
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-1"></div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12 col-md-1"></div>

                        <div className="col-sm-12 col-md-2">
                            <div className="cardContainer">
                                {/* <i class="bi bi-filetype-html icons"></i> */}
                                <svg xmlns="http://www.w3.org/2000/svg" className="icons" width="1em" height="1em" viewBox="0 0 128 128"><path fill="#ffc107" d="m19.569 27l8.087 89.919l36.289 9.682l36.39-9.499L108.431 27zM91.61 47.471l-.507 5.834L90.88 56H48.311l1.017 12h40.54l-.271 2.231l-2.615 28.909l-.192 1.69L64 106.964v-.005l-.027.012l-22.777-5.916L39.65 84h11.168l.791 8.46l12.385 3.139l.006-.234v.012l12.412-2.649L77.708 79H39.153l-2.734-30.836L36.152 45h55.724zM27.956 1.627h5.622v5.556h5.144V1.627h5.623v16.822h-5.623v-5.633h-5.143v5.633h-5.623zm23.782 5.579h-4.95V1.627h15.525v5.579h-4.952v11.243h-5.623zm13.039-5.579h5.862l3.607 5.911l3.603-5.911h5.865v16.822h-5.601v-8.338l-3.867 5.981h-.098l-3.87-5.981v8.338h-5.502V1.627zm21.736 0h5.624v11.262h7.907v5.561H86.513z"/></svg>
                                <div className="cardTitle">
                                    HTML5, Unity
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-2">
                            <div className="cardContainer">
                                {/* <img src="./assets/images/icons/Icon_GamingFramework.svg" /> */}
                                <svg xmlns="http://www.w3.org/2000/svg" className="icons" width="1em" height="1em" viewBox="0 0 512 512"><path fill="#ffc107" d="m318 123.645l-61.5 35.7l-61.76-35.7l61.76-35.7zm93.68 54.19l-61.76 35.7l61.76 35.7l61.5-35.7zm-294.39 80.64l61.76 35.7l61.5-35.7l-61.5-35.7zm139.52-80.57l-61.76 35.7l61.76 35.7l61.5-35.7zM31 298.365l62 35.69v-71l-62-35.65v71zm373-26l-62 35.69v70.94l62-35.66zm-225.11-139.4l-61.76 35.7l61.76 35.7l61.5-35.7zM109 343.305l62 35.69v-70.94l-62-35.69v71zm225.41-120.45l-61.76 35.7l61.76 35.7l61.5-35.7zM249 353.055l-62-35.7v71l62 35.7zm77-35.67l-61 35.67v70.94l61-35.66zm8.07-184.5l-61.76 35.7l61.76 35.7l61.5-35.7zm-232.6 44.95l-61.77 35.7l61.76 35.7l61.5-35.7zM481 227.565l-61 35.66v70.94l61-35.66zm-286.11 75.93l61.76 35.7l61.5-35.7l-61.5-35.7z"/></svg>
                                <div className="cardTitle">
                                    Cross platform compatibility
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-2">
                            <div className="cardContainer">
                                {/* <img src="./assets/images/icons/Icon_Hexagon.svg" />    */}
                                <svg xmlns="http://www.w3.org/2000/svg" className="icons" width="1em" height="1em" viewBox="0 0 512 512"><path fill="#ffc107" d="M134.865 113.46c-7.463.026-15.134 2.977-22.38 8.663c-9.658 7.582-18.055 20.015-22.157 35.324c-4.102 15.31-3.047 30.274 1.527 41.67c4.575 11.396 12.238 18.958 21.91 21.55c9.67 2.59 20.09-.126 29.75-7.708c9.658-7.583 18.055-20.016 22.157-35.325s3.047-30.274-1.527-41.67c-4.575-11.396-12.24-18.96-21.91-21.55a28 28 0 0 0-7.37-.956zm242.27 0a28 28 0 0 0-7.37.954c-9.672 2.59-17.337 10.155-21.91 21.55c-4.576 11.397-5.63 26.362-1.527 41.67c4.102 15.31 12.497 27.743 22.156 35.325c9.66 7.58 20.08 10.297 29.75 7.706c9.67-2.59 17.338-10.153 21.912-21.55c4.575-11.395 5.626-26.36 1.524-41.67c-4.102-15.308-12.495-27.74-22.154-35.323c-7.245-5.686-14.918-8.638-22.38-8.664zM88.387 226.04c-10.146 2.133-18.06 6.477-24.82 12.648c-9.882 9.023-17.314 22.553-23.03 39.7c-10.6 31.8-15.044 75.344-22.062 120.153H150.97c-.04-44.9-.513-88.924-6.804-121.28c-3.203-16.47-7.97-29.787-14.098-38.57c-6.872 1.342-13.968 1.24-20.963-.635c-8.012-2.147-14.98-6.376-20.718-12.014zm335.226 0c-5.74 5.64-12.705 9.868-20.718 12.015c-6.994 1.874-14.09 1.978-20.96.636c-6.127 8.785-10.9 22.1-14.1 38.57c-6.292 32.356-6.766 76.38-6.804 121.28h132.493c-7.017-44.808-11.462-88.353-22.062-120.153c-5.714-17.146-13.145-30.676-23.028-39.7c-6.758-6.17-14.672-10.514-24.82-12.646zm-240.615 69.5v23H201v-23zm80.002 0v23h18.002v-23zm-94.857 41c.18 4.613.313 9.292.425 14h174.864c.112-4.708.248-9.387.427-14H168.142z"/></svg>
                                <div className="cardTitle">
                                    Multiplayer gaming experience
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-2">
                            <div className="cardContainer">
                                {/* <img src="./assets/images/icons/Icon_Hexagon.svg" /> */}
                                <svg xmlns="http://www.w3.org/2000/svg" className="icons" width="1em" height="1em" viewBox="0 0 24 24"><path fill="#ffc107" d="m5 12l2-4V7H4v1h2l-2 4m5 0l2-4V7H8v1h2l-2 4m5 0l2-4V7h-3v1h2l-2 4m9-10c-1.1 0-2 .9-2 2c0 .7.4 1.4 1 1.7V17h-3v-2c.6 0 1-.4 1-1V5c0-.6-.4-1-1-1h-3.8c-.8-1.2-2.2-2-3.7-2s-2.9.8-3.7 2H2c-.6 0-1 .4-1 1v9c0 .6.4 1 1 1v7h15v-3h3c1.1 0 2-.9 2-2V5.7c.6-.3 1-1 1-1.7c0-1.1-.9-2-2-2m-8 17H6v-2h7zm3-6H3V6h13z"/></svg>
                                <div className="cardTitle">
                                    Slot games, Card games, Table games
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-2">
                            <div className="cardContainer">
                                {/* <img src="./assets/images/icons/Icon_Hexagon.svg" /> */}
                                <svg xmlns="http://www.w3.org/2000/svg" className="icons" width="1em" height="1em" viewBox="0 0 16 16"><path fill="#ffc107" fill-rule="evenodd" d="M11.596 1.164a.75.75 0 0 1 1.054.118a10.75 10.75 0 0 1-.017 13.438a.75.75 0 1 1-1.17-.94a9.25 9.25 0 0 0 .015-11.562a.75.75 0 0 1 .118-1.054m-3.84 2.97a.75.75 0 0 1 1.048.166a6.28 6.28 0 0 1 0 7.381a.75.75 0 1 1-1.214-.881a4.78 4.78 0 0 0 0-5.619a.75.75 0 0 1 .167-1.047ZM3 10a2 2 0 1 0 0-4a2 2 0 0 0 0 4" clip-rule="evenodd"/></svg>
                                <div className="cardTitle">
                                    Live dealer games
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-1"></div>
                    </div>
                </div>
            </div>

            <div className="seperator"><div className="container"></div></div>

            <WhyChooseUs></WhyChooseUs>

            <div className="seperator"><div className="container"></div></div>

            <JoinUs></JoinUs>

            <Footer />
        </div>
    );
}

export default Services;